import { IdentityDisplay } from "@oaktyres/model";
import { useAccountNew } from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Loader,
  Panel,
  Text,
  UserFlag,
} from "@oaktyres/ui";
import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

const LabeledInfo = ({ label, value }: { label: string; value: string }) => (
  <Box>
    <Text fontWeight={600} color="grey2">
      {label}
    </Text>
    <Text>{value}</Text>
  </Box>
);

const ContactInfo = ({ user }: { user: IdentityDisplay }) => {
  return (
    <Flex
      justifyContent={"space-between"}
      flexDirection={["column", "row"]}
      style={{ gap: 12 }}
    >
      <UserFlag user={user} showPhone />
      <Flex alignItems={"center"} justifyContent="center">
        {user.phoneNumber != null && (
          <IconButton
            buttonStyle="filled"
            color="primary"
            icon={FaPhone}
            onClick={() => window.location.replace(`tel:${user.phoneNumber}`)}
          />
        )}
        <IconButton
          ml={1}
          buttonStyle="filled"
          color="primary"
          icon={FaEnvelope}
          onClick={() => window.location.replace(`mailto:${user.email}`)}
        />
      </Flex>
    </Flex>
  );
};

export const AccountDetailsPane = () => {
  const [scopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);

  if (account.data == null) {
    return (
      <Panel width="100%" p={5}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Panel width="100%" p={3}>
      <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
        <Text fontWeight={600} mb={2}>
          Account Details
        </Text>
        <Grid gridGap={1} gridTemplateColumns={["1fr", "1fr 1fr"]}>
          <LabeledInfo label="Account Name" value={account.data.name} />
          <LabeledInfo label="Account Code" value={account.data.code} />
        </Grid>
      </Flex>
      {account.data.businessDevelopmentManager && (
        <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
          <Text fontWeight={600} mb={2}>
            Business Development Manager
          </Text>
          <ContactInfo user={account.data.businessDevelopmentManager!} />
        </Flex>
      )}
      {account.data.accountManager && (
        <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
          <Text fontWeight={600} mb={2}>
            Account Manager
          </Text>
          <ContactInfo user={account.data.accountManager!} />
        </Flex>
      )}
      {account.data.addresses.map((x, i) => (
        <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
          <Text fontWeight={600} mb={2}>
            Delivery Address {i + 1} {x.isDefault && "(Default)"}
          </Text>
          <Grid gridGap={1} gridTemplateColumns={["1fr", "1fr 1fr"]}>
            <LabeledInfo label="Address 1" value={x.address1} />
            <LabeledInfo label="Address 2" value={x.address2} />
            <LabeledInfo label="City" value={x.city} />
            <LabeledInfo label="County" value={x.county} />
            <LabeledInfo label="Post Code" value={x.postcode} />
          </Grid>
        </Flex>
      ))}
    </Panel>
  );
};
