import { TyreAvailability } from "@oaktyres/model";
import {
  BasketItem,
  IStockItem,
  LoadedBasketItem,
  useManufacturers,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  Loader,
  ManufacturerLabel,
  Text,
  LinkButton,
  Panel,
  formatCurrency,
} from "@oaktyres/ui";
import { format, fromUnixTime, isToday } from "date-fns";
import { sum } from "lodash";
import React from "react";
import { IconType } from "react-icons";
import { FaPeopleCarry, FaTrash, FaTruck } from "react-icons/fa";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import defaultTyreSrc from "../../img/defaulttyre.svg";
import opLogo from "../../img/op.png";

const TyreImageContainer = styled.div<LayoutProps>`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
  min-width: 0;
  width: 200px;
  height: 130px;
  overflow: hidden;
  padding: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  margin-right: 18px;
  position: relative;
  ${layout};
`;

const DefaultTyreImage = styled.img`
  position: absolute;
  top: 24px;
  width: 95%;
  max-width: 100%;
  opacity: 0.3;
`;

const TyreImage = styled.img`
  position: absolute;
  top: 18px;
  max-width: 100%;
  width: 95%;
`;

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 120%;
  object-fit: contain;
`;

DefaultTyreImage.defaultProps = {
  src: defaultTyreSrc,
};

type LoadedBasketItemDisplayProps = {
  showRewardsPanel: boolean;
  item: LoadedBasketItem;
  onDecrement: () => void;
  onIncrement: () => void;
  onRemove: () => void;
  onShowModal: () => void;
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const formatCutoff = (cutoff: number) => {
  const date = fromUnixTime(cutoff);
  const dayName = isToday(date) ? "today" : days[date.getDay()];

  return `${format(date, "HH:mm")} ${dayName}`;
};

const hasRewards = (item: IStockItem): boolean =>
  [item.rewards?.bdf, item.rewards?.dsp, item.rewards?.points].some(
    (x) => x != null && x > 0,
  );

const RewardsPanel = ({
  points,
  dsp,
  bdf,
}: {
  points: number;
  dsp: number;
  bdf: number;
}) => (
  <Panel boxShadow="none" p={3} backgroundColor="grey6">
    <Flex alignItems={"flex-start"}>
      <Box mr={2}>
        <Flex>
          <Text fontStyle={"italic"} fontSize={1} color="grey1">
            {points} Rewards Points
          </Text>
        </Flex>
        <Flex>
          <Text fontStyle={"italic"} fontSize={1} color="grey1">
            {dsp} Dealer Support Points
          </Text>
        </Flex>
        <Flex>
          <Text fontStyle={"italic"} fontSize={1} color="grey1">
            {(bdf / 100).toLocaleString("en-GB", {
              style: "currency",
              currency: "gbp",
            })}{" "}
            Business Dev. Fund
          </Text>
        </Flex>
      </Box>
      <img src={opLogo} width={24} />
    </Flex>
  </Panel>
);

export function LoadedBasketItemDisplay({
  item,
  onIncrement,
  onDecrement,
  onRemove,
  onShowModal,
  showRewardsPanel,
}: LoadedBasketItemDisplayProps) {
  const brands = useManufacturers();

  let q = item.qty;
  const isColl = item.supply !== "delivery";

  const dispatches: [number, TyreAvailability][] = [];

  let qtyAvailable = isColl
    ? sum(item.data.collection.map((x) => x.qtyAvailable))
    : sum(item.data.availability.map((x) => x.qtyAvailable));

  for (const a of item.data.availability) {
    if (q === 0) {
      break;
    }

    const aQty = Math.min(q, a.qtyAvailable);
    dispatches.push([aQty, a]);
    q -= aQty;
  }

  const brand = (brands.data ?? []).find(
    (x) => x.crossReference === item.data.manufacturer,
  );

  const collNow = item.data.collection
    .filter((x) => x.source.id === item.supply && x.availableNow)
    .reduce((acc, val) => acc + val.qtyAvailable, 0);
  const collLater = item.data.collection
    .filter((x) => x.source.id === item.supply && !x.availableNow)
    .reduce((acc, val) => acc + val.qtyAvailable, 0);

  let avails: { color: string; text: string; icon: React.ReactNode }[] = [];

  if (isColl) {
    const neededLater = Math.max(0, item.qty - collNow);
    const neededNow = item.qty - neededLater;

    avails = [
      {
        color: "positive",
        text: `${neededNow} ready for collection now`,
        icon: <FaPeopleCarry />,
      },
    ];

    if (neededLater > 0) {
      avails.push({
        color: "info",
        text: `${neededLater} ready tomorrow @ 7am`,
        icon: <FaPeopleCarry />,
      });
    }
  } else {
    avails = dispatches.map(([count, item]) => ({
      color: item.isNextRun ? "positive" : "info",
      text: item.orderedStock
        ? `${count} dispatched within 5 days`
        : `${count} dispatched @ ${formatCutoff(item.dispatch)}`,
      icon: item.orderedStock ? null : <FaTruck title="Next Van" />,
    }));
  }

  const qtyNotAvail = q > qtyAvailable;

  return (
    <Flex
      mb={3}
      flexDirection={["column", "row"]}
      borderBottom={1}
      pb={3}
      alignItems="center"
    >
      <TyreImageContainer width={["100%", "200px"]} display={["none", "flex"]}>
        {item.data.images.length > 0 ? (
          item.data.type === "tyre" ? (
            <TyreImage
              src={process.env.REACT_APP_STATIC_ROOT + item.data.images[0]}
            />
          ) : (
            <ProductImage
              src={process.env.REACT_APP_STATIC_ROOT + item.data.images[0]}
            />
          )
        ) : (
          <DefaultTyreImage />
        )}
      </TyreImageContainer>
      <Flex
        flexDirection="column"
        flex={1}
        alignItems="flex-start"
        pt={1}
        mb={[3, 0]}
      >
        {brand && (
          <div style={{ fontWeight: 600 }}>
            <ManufacturerLabel
              mb={1}
              code={brand.crossReference}
              name={`${brand.name} ${item.data.name}`}
              icon={brand.icon}
              staticRoot={process.env.REACT_APP_STATIC_ROOT as string}
            />
          </div>
        )}
        {item.data.fitment && (
          <Text fontWeight={600} fontSize={2} lineHeight={1} mb={1}>
            {item.data.fitment}
          </Text>
        )}
        <LinkButton onClick={onShowModal} fontSize={1}>
          {item.code}
        </LinkButton>
      </Flex>
      {hasRewards(item.data) && showRewardsPanel && (
        <Flex display={["none", "none", "none", "flex"]} mx={3}>
          <RewardsPanel
            points={item.data.rewards?.points ?? 0}
            dsp={item.data.rewards?.dsp ?? 0}
            bdf={item.data.rewards?.bdf ?? 0}
          />
        </Flex>
      )}
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="center"
        alignItems="center"
      >
        <Flex flexDirection="column" mr={[0, 3]} mb={0} order={[1, 0]}>
          <Flex mb={2} alignItems="stretch">
            <Button
              color="danger"
              minWidth={0}
              onClick={onRemove}
              p={0}
              width="30px"
            >
              <FaTrash style={{ margin: 0 }} fontSize="0.8em" />
            </Button>
            <Button
              minWidth={0}
              p={1}
              ml={1}
              mr={2}
              width="30px"
              onClick={onDecrement}
            >
              -
            </Button>
            <Flex alignItems="center">
              <Text as="span" fontWeight={600} style={{ whiteSpace: "nowrap" }}>
                {item.qty} / {qtyAvailable} available
              </Text>
            </Flex>
            <Button
              minWidth={0}
              p={1}
              ml={2}
              width="30px"
              onClick={onIncrement}
            >
              +
            </Button>
          </Flex>
          <Box>
            {qtyNotAvail && (
              <Text
                color="danger"
                fontSize={1}
                textAlign="center"
                fontWeight={600}
              >
                Quantity no longer available
              </Text>
            )}
            {avails.map((x, i) => (
              <Text color={x.color} textAlign="center" fontSize={1} key={i}>
                {x.text} {x.icon}
              </Text>
            ))}
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          mx={3}
          alignItems="flex-end"
          order={[0, 1]}
          width={110}
        >
          <Text fontSize={4} fontWeight={600} lineHeight={1}>
            {item.data.price == null
              ? "-"
              : formatCurrency(item.data.price.currency)(
                  (item.data.price.value ?? 0) * item.qty,
                )}
          </Text>
          <Text fontSize={2} fontWeight={600} color="grey2">
            {item.data.price == null
              ? "-"
              : formatCurrency(item.data.price.currency)(
                  item.data.price.value ?? 0,
                )}{" "}
            X {item.qty}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function LoadingBasketItemDisplay({ item }: { item: BasketItem }) {
  return (
    <Flex flexDirection={["column", "row"]} mb={2}>
      <TyreImageContainer width={["100%", "200px"]}>
        <DefaultTyreImage />
        <Loader overlay />
      </TyreImageContainer>
      <Flex pt={2}>
        <Text fontSize={4} fontWeight={600}>
          Loading
        </Text>
      </Flex>
    </Flex>
  );
}
